<!--
 * @Descripttion:回收情况提醒页
 * @version: 
 * @Author: 86131
 * @Date: 2021/3/31 16:34
-->
<template>
  <div class="recycle-remind">
    <div class="title">没有回收数据</div>
    <div class="main">
      <div class="main-top">此问卷尚未有答卷，没有回收数据。请先派发问卷！</div>
      <div class="main-bottom"><a-button class="release" @click="handleReleaseQuestion">问卷派发</a-button></div>
    </div>
    <div class="footer"></div>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'

export default {
  name: "QuestionPublishing",
  setup () {
    const router = useRouter()
    const route = useRoute()
    const handleReleaseQuestion = () => {
      const id = route.params.id
      router.push(`/detail/${id}/dispatchQuestion`)
    }
    return {
      handleReleaseQuestion,
    }
  }
}
</script>
<style lang="less" scoped>
.recycle-remind {
  margin-top: 2rem;
  width: 1300px;
  height: 881px;
  background: #FFFFFF;
  border: 1px solid #fff;
  border-radius: 6px;
  .title {
    margin-top: 61px;
    width: 100%;
    height: 2rem;
    font-weight: bold;
    font-size: 26px;
    display: flex;
    justify-content: center;
  }
  .main {
    width: 100%;
    height: 8rem;
    .main-top {
      width: 100%;
      margin: 48px 0 32px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 100;
    }
    .main-bottom {
      width: 100%;
      height: 35%;
      display: flex;
      justify-content: center;
      align-items: center;
      .release {
        width: 140px;
        height: 36px;
        line-height: 30px;
        font-size: 18px;
        background: #00A0E9;
        color: #fff;
        border-radius: 2px;
      }
    }
  }
}
</style>