<!--
 * @Description: 问卷基础信息
 * @version: 
 * @Author: PSG
 * @Date: 2021-05-19 17:11:54
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-09 15:55:31
-->
<template>
  <div class="top">
    <h1 class="top-title ellipsis">{{questionInfo.title}}</h1>
    <div class="top-info">
      <div class="top-info-id">ID: {{questionInfo.dateNumId}}</div>
      <div class="top-info-status">
        <template v-if="questionInfo.ifRelease"><span class="top-info-status-release"></span>已发布</template>
        <template v-else><span class="top-info-status-norelease"></span>未发布</template>
      </div>
      <div class="top-info-total">答卷：{{questionInfo.validQuestionnaireNum || 0}} / {{(questionInfo.validQuestionnaireNum || 0) + (questionInfo.inValidQuestionnaireNum || 0)}} </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionnaireBasic',
  props: [
    'questionInfo',
  ],
  setup () {
    
  }
}
</script>

<style lang="less" scoped>
.top {
  display: flex;
  background-color: #fff;
  border-radius: 4px;
  .top-title {
    width: 605px;
    font-size: 26px;
    font-weight: bold;
    margin: 32px 0 32px 30px;
  }
  .top-info {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 40px;
    color: #7F7F88;
    .top-info-id {
      width: 173px;
      height: 30px;
      font-size: 20px;
      line-height: 30px;
      border: 1px solid #E1E1E8;
      padding: 0 8px;
    }
    .top-info-status {
      display: flex;
      align-items: center;
      font-size: 20px;
      .top-info-status-release {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 16px;
        background-color: #d9001b;
        line-height: 16px;
        margin-right: 10px;
      }
      .top-info-status-norelease {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 16px;
        background-color: #7f7f7f;
        line-height: 16px;
        margin-right: 10px;
      }
    }
    .top-info-total {
      font-size: 20px;
    }
  }
}
</style>
