<!--
 * @Descripttion:回收情况
 * @version:
 * @Author: 86131
 * @Date: 2021/3/31 16:29
-->
<template>
  <div class="recycle-situation">
    <RecycleRemind v-if="totalQuestionnaireNum === 0"/>
    <div class="recycle-detail" v-else>
      <SideBar @tabChangeClick="handleTabChangeClick"/>
      <div class="main">
        <QuestionnaireBasic :questionInfo="questionInfo"/>
        <AnswerDetails v-if="curTabIndex === 0" @refresh="handleRefresh" :questionInfo="questionInfo"/>
        <ChannelAnalysis v-if="curTabIndex === 1"/>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from "@/views/detail/recycleBin/components/SideBar"
import RecycleRemind from "@/views/detail/recycleBin/components/RecycleRemind"
import QuestionnaireBasic from "@/views/detail/recycleBin/components/QuestionnaireBasic"
import AnswerDetails from "@/views/detail/recycleBin/answerDetails/index"
import ChannelAnalysis from "@/views/detail/recycleBin/channelAnalysis/index"
import { ref } from 'vue';
import { watchEffect } from '@vue/runtime-core';
import { useRoute, useRouter } from 'vue-router'
import { getQuestionnaireList } from '@/api/questionnaire'


/**
 * 获取问卷基本信息
 */
const getQuestionnaireInfoEffect = () => {
  const route = useRoute()
  const questionInfo = ref({})
  const totalQuestionnaireNum = ref()
  let isAreadlyRefresh = false
  watchEffect(async () => {
    if (isAreadlyRefresh) return
    const id = route.params.id
    const res = await getQuestionnaireList({id})
    if (res.status === 200 && res.data?.code === '00000') {
      questionInfo.value = res.data.data?.content[0] || {}
      totalQuestionnaireNum.value = (questionInfo.value.validQuestionnaireNum || 0) + (questionInfo.value.inValidQuestionnaireNum || 0)
    }
  })

  const handleRefresh = async () => {
    isAreadlyRefresh = true
    const id = route.params.id
    const res = await getQuestionnaireList({id})
    if (res.status === 200 && res.data?.code === '00000') {
      questionInfo.value = res.data.data?.content[0] || {}
    }
  }
  return {
    questionInfo,
    totalQuestionnaireNum,
    handleRefresh,
  }
}

/**
 * 左侧tab标签页控制相关
 */
const tabControlEffect = () => {
  const curTabIndex = ref(0)
  const handleTabChangeClick = (index) => {
    curTabIndex.value = index
  }
  return {
    curTabIndex,
    handleTabChangeClick,
  }
}

export default {
  name: "Recycle",
  components: {
    SideBar,
    RecycleRemind,
    QuestionnaireBasic,
    AnswerDetails,
    ChannelAnalysis,
  },
  setup () {
    const { questionInfo, totalQuestionnaireNum, handleRefresh } = getQuestionnaireInfoEffect()
    const { curTabIndex, handleTabChangeClick } = tabControlEffect()
    return {
      questionInfo,
      totalQuestionnaireNum,
      handleRefresh,
      curTabIndex,
      handleTabChangeClick,
    }
  }
}
</script>

<style lang="less" scoped>
.recycle-situation {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: calc(100%);
  overflow: hidden;
  .recycle-detail {
    margin: 50px auto 0 auto;
    width: 1300px;
    display: flex;
    .main {
      width: 1171px;
      height: 100%;
      &:hover {
        overflow-y: auto;
      }
    }
  }
}
</style>