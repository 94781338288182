<!--
 * @Description: 侧边功能导航组件
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-18 14:57:36
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-12 16:49:13
-->
<template>
  <div class="sidebar">
    <div class="module">
      <div class="module-box" :class="{active: current === 0}" @click="tabChangeClick(0)">
        <div id="setting" class="iconfont">&#xe664;</div>
        <div class="title">答卷详情</div>
      </div>
      <div class="module-box" :class="{active: current === 1}" @click="tabChangeClick(1)">
          <div id="setting" class="iconfont">&#xe613;</div>
          <div class="title">渠道分析</div>
        </div>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'

export default {
  name: 'RecycleSideBar',
  props: [],
  emits: ['changeTab'],
  setup(props, { emit }) {
    const current = ref(0)
    const tabChangeClick = (index) => {
      current.value = index
      emit('tabChangeClick', index)
    }
    return {
      current,
      tabChangeClick,
    }
  }
}
</script>

<style scoped lang="less">
.sidebar {
  width: 129px;
  height: 100%;
  background-color: #fff;
  border-right: 8px solid #eff2f5;
}
.module {
  padding-top: 32px;
  .active {
    color: #fff;
    background-color: #00A0E9;
    .iconfont {
      color: #fff;
    }
  }
  .module-box {
    width: 100%;
    height: 49px;
    line-height: 49px;
    display: flex;
    cursor:pointer;
    .iconfont {
      margin-left: 15px;
      margin-right: 8px;
      font-size: 16px;
    }
    &:hover {
      // color: #fff;
      background-color: #00A0E9;
    }
  }
}
.iconfont {
  font-size: 32px;
  color: #575758;
  margin-bottom: 12px;
}
</style>